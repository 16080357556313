// Renders the search results below the SearchBar
import React, { useState, useEffect, useContext } from "react";
//import { useNavigate } from 'react-router-dom';

import { AppContext } from "../../AppContext";

import PaginationSection from './PaginationSection';
import SearchResultManualsEntry from './SearchResultManualsEntry';
import SearchResultPartsRCEntry from "./SearchResultPartsRCEntry";
import SearchResultPartsSNLEntry from "./SearchResultPartsSNLEntry";
import SearchResultPartsWebEntry from "./SearchResultPartsWebEntry";
import SearchResultBFEntry from './SearchResultBFEntry';
import SearchResultPartsBloomfireCard from "./SearchResultPartsBloomfireCard";
//import CheckBox from '../Checkbox';

import { Divider } from "../catalyst/divider";
import { Button } from '../catalyst/button';
import { Table, TableBody } from '../catalyst/table';
import { Alert, AlertTitle, AlertActions } from '../catalyst/alert';

import { CircleHelp, LocateFixed } from 'lucide-react';

function SearchResultsColumns({ urlMfgName, urlSN, urlMN, urlRQ }) {
	const { searchResultsManuals, searchResultsBF, searchResultsPartsRC, searchResultsPartsSNL, searchResultsPartsWebsite } = useContext(AppContext);

	// State for the alert that asks if the user wants to show the case numbers
	const [isOpen, setIsOpen] = useState(false);

// Pagination Logic
	//Key is the model name, the value is the current page number (inititalizes as 1)
	const [manPageNum, setManPageNum] = useState({});
	//const [partPageNum, setPartPageNum] = useState({});
	const [bfPageNum, setBFPageNum] = useState({});
	const [RCPageNum, setRCPageNum] = useState({});
	const [SNLPageNum, setSNLPageNum] = useState({});
	const [websitePageNum, setWebsitePageNum] = useState({});



	const itemsPerPage = 7; //Number of items per page

	//Initialize the page numbers for each model
	useEffect(() => {
		function initializePagination(results, setPageNum, typeOfResult) {

			const newPageNums = {};

			// Makes the dictionary keys the model name and sets the page number to 1
			Object.keys(results).forEach((key) => {
				newPageNums[key] = 1;
			});

			setPageNum(newPageNums);
		};

		initializePagination(searchResultsManuals, setManPageNum, "Manuals");
		//initializePagination(searchResultsParts, setPartPageNum, "Parts");
		initializePagination(searchResultsBF, setBFPageNum, "Bloomfire Posts");
		initializePagination((searchResultsPartsRC.parts ? searchResultsPartsRC.parts : {}), setRCPageNum, "Resolved Cases");
		initializePagination(searchResultsPartsSNL, setSNLPageNum, "Serial Number Lookup");
		initializePagination(searchResultsPartsWebsite, setWebsitePageNum, "Website Lookup");


		return;
	}, [searchResultsManuals, searchResultsBF, searchResultsPartsRC, searchResultsPartsSNL, searchResultsPartsWebsite]);


	//Function to handle when a new page number is selected
	//Updates the model's page number in the correct dictionary
	function handlePageChange (typeOfResult, searchResultsTitle, newPageNumber) {
		console.log("HandlePageChange: ", typeOfResult, searchResultsTitle, newPageNumber);
		if (typeOfResult === "Manuals") {
			setManPageNum((prev) => ({ ...prev, [searchResultsTitle]: newPageNumber }));
		// } else if (typeOfResult === "Parts") {
		//	 setPartPageNum((prev) => ({ ...prev, [searchResultsTitle]: newPageNumber }));
		} else if (typeOfResult === "Resolved Cases") {
			setRCPageNum((prev) => ({ ...prev, [searchResultsTitle]: newPageNumber }));
		} else if (typeOfResult === "Serial Number Lookup") {
			setSNLPageNum((prev) => ({ ...prev, [searchResultsTitle]: newPageNumber }));
		} else if (typeOfResult === "Website Lookup") {
			setWebsitePageNum((prev) => ({ ...prev, [searchResultsTitle]: newPageNumber }));
		} else {
			setBFPageNum((prev) => ({ ...prev, [searchResultsTitle]: newPageNumber }));
		}
	};


	// // Variable which stores the query in the secondary search bar to refine results
	// const [refineQuery, setRefineQuery] = useState("");
	// const [refineQueryBuffer, setRefineQueryBuffer] = useState(""); //Buffer to store the refine query before it is applied
	// 
	// // Function will transfer refineQueryBuffer to refineQuery, which then gets applied to filterResults
	// // This is used to refine the search results only when the Refine Search button is clicked
	// // If this is removed, and refineQueryBuffer is directly set to refineQuery, the search results will
	// // be refined as the user types in the search bar automatically
	// function handleRefineQuerySubmit(e) {
	//	 e.preventDefault();
	//	 setRefineQuery(refineQueryBuffer);
	// };



	//Variable to check whether the obsolete parts are included in the map or not
	const [keepObsolete, setKeepObsolete] = useState(true);
	const handleCheckboxChange = () => {
		setKeepObsolete(!keepObsolete);
	};

	// Function to filter values based on refineQuery and keepObsolete
	function filterResults(values) {
		return values.filter(value => {
			// Filters for results by obsoleteness if keepObsolete is True
			const matchesObsoleteFilter = keepObsolete || value.obsolete !== "Y";

			// // Filters for results by refineQuery if refineQuery is not empty
			// // If refineQuery is empty, it will always return true (aka no results filtered out)
			// let matchesRefineQuery = !refineQuery;

			// const fieldsToCheck = ["OptimizedDescription", "Description", "MfgName", "StockCode", "MfgStockCode"];

			// // Checks that the value has OptimizedDesc and Desc fields
			// // If value doesn't have the fields (aka models and Bloomfire posts),
			// // it sets the substring filter true (aka item always passes the substring filter check)
			// if (value.OptimizedDescription && value.Description) {
			//	 //Checks that one of the fields in fieldsToCheck has refineQuery
			//	 //Doesn't consider case sensitivity (does so by comparing the lowercase versions of the fields and refineQuery)
			//	 matchesRefineQuery = fieldsToCheck.some(
			//		 field => value[field] &&
			//		 ((value[field]).toLowerCase()).includes(refineQuery.toLowerCase())
			//	 );
			// } else {
			//	 matchesRefineQuery = true;
			// }

			return matchesObsoleteFilter;// && matchesRefineQuery;
		});
	}


	//Takes the search result dictionary of models or parts and maps it into a container
	//with a list of entries
	function searchResultListMap(resultToMap, typeOfResult, rcCaseNums = 0) {
		// Get the correct page number dictionary based on the type of result
		const correctPageNumDict = typeOfResult === "Manuals" ? manPageNum : 
															 typeOfResult === "Resolved Cases" ? RCPageNum : 
															 typeOfResult === "Serial Number Lookup" ? SNLPageNum : 
															 typeOfResult === "Website Lookup" ? websitePageNum : 
															 bfPageNum;
		
		// Function to render the correct result row based on the type of result
		function correctResultRow(typeOfResult, index, value, searchResultsTitle) {
			if (typeOfResult === "Manuals") {
				return (
					<SearchResultManualsEntry
						key={index}
						tableRowNum={index}
						manualPath={value} //The .pdf name of the file
						manualFileName={searchResultsTitle} //The name of the model that the manual is being referred for
					/>
				)
			} else if (typeOfResult === "Resolved Cases") {
				// NOTE: BLOOMFIRE AND TEMPORARY
				if (value.imageurl === "BLOOMFIRE" && value.obsolete === "BLOOMFIRE") {
					return (
						<SearchResultPartsBloomfireCard
							key={index}
							tableRowNum={index}
							ptPartName = {value.stockcode}
							mfgPartName = {value.mfgstockcode}
						/>
					)
				}

				return (
					<SearchResultPartsRCEntry
						key={index}
						tableRowNum={index}
						optPartDesc={value.optimizeddescription} //Optimized part description
						mfgName={value.mfgname} //Manufacturer name
						repPartName={value.replacedby} // the new part to use instead of this one
						ptPartName={value.stockcode} //Part Name given by Partstown
						mfgPartName={value.mfgstockcode} //Part Name given by the manufacturer (basically ptPartName without the mfg's prefix)
						partDesc={value.description} //Part Description
						imgURL={value.imageurl} //Image URL
						partObsolete={value.obsolete} //"Y" or "N" to indicate if the part is obsolete
						rcNum={value.rcNum} //Indicates how many times the part was used in returned resolved cases
						partType={typeOfResult} //Type of part (resolved cases, serial number lookup, etc.)
					/>
				);
			} else if (typeOfResult === "Serial Number Lookup" ) {
				return (
					<SearchResultPartsSNLEntry
						key={index}
						tableRowNum={index}
						optPartDesc={value.optimizeddescription} //Optimized part description
						mfgName={value.mfgname} //Manufacturer name
						repPartName={value.replacedby} // the new part to use instead of this one
						ptPartName={value.stockcode} //Part Name given by Partstown
						mfgPartName={value.mfgstockcode} //Part Name given by the manufacturer (basically ptPartName without the mfg's prefix)
						partDesc={value.description} //Part Description
						imgURL={value.imageurl} //Image URL
						partObsolete={value.obsolete} //"Y" or "N" to indicate if the part is obsolete
						partType={typeOfResult} //Type of part (serial number lookup or website lookup)
					/>
				);
			} else if (typeOfResult === "Website Lookup") {
				// NOTE: BLOOMFIRE AND TEMPORARY
				if (value.imageurl === "BLOOMFIRE" && value.obsolete === "BLOOMFIRE") {
					return (
						<SearchResultPartsBloomfireCard
							key={index}
							tableRowNum={index}
							ptPartName = {value.stockcode}
							mfgPartName = {value.mfgstockcode}
						/>
					)
				}
				
				return (
					<SearchResultPartsWebEntry
						key={index}
						tableRowNum={index}
						optPartDesc={value.optimizeddescription} //Optimized part description
						mfgName={value.mfgname} //Manufacturer name
						repPartName={value.replacedby} // the new part to use instead of this one
						ptPartName={value.stockcode} //Part Name given by Partstown
						mfgPartName={value.mfgstockcode} //Part Name given by the manufacturer (basically ptPartName without the mfg's prefix)
						partDesc={value.description} //Part Description
						imgURL={value.imageurl} //Image URL
						partObsolete={value.obsolete} //"Y" or "N" to indicate if the part is obsolete
						partType={typeOfResult} //Type of part (serial number lookup or website lookup)
					/>
				);
			} else {
				return (
					<SearchResultBFEntry
						key={index}
						tableRowNum={index}
						name={value.instance.name ? value.instance.name : (value.highlight ? value.highlight.name : "")} //Only one entry in the name array; checks instance field first and then checks highlight field
						description={value.instance.description ? value.instance.description : (value.highlight ? value.highlight.description : [])} //Description of post. instance field passes in the description as a string and highlight field passes in as a one item array
						post_content={value.highlight ? value.highlight.post_content : []} //Array of lines within the user_generated post content
						doc_body={value.highlight ? value.highlight.doc_body : []} //Array of all instances of keyword in the text
						postID={value.instance.id ? value.instance.id : -1} //Post id
						query={searchResultsTitle}
						url={value.instance.url ? value.instance.url : ""}
						lastUpdated={value.instance.updated_at ? value.instance.updated_at : ""}
						author={value.instance.author ? value.instance.author : {"first_name": "","last_name": ""}}
					/>
				);
			}
		}
	
		// Maps a list of manuals or parts per model
		return Object.entries(resultToMap).map(([searchResultsTitle, values]) => {
			// Filter the values based on keepObsolete and refineQuery
			const filteredValues = filterResults(values);
	
			// Get the current page number for the search results title
			let currentPage = correctPageNumDict[searchResultsTitle];
	
			// Get the paginated values based on the current page number
			let lastIndex = currentPage * itemsPerPage;
			let firstIndex = lastIndex - itemsPerPage;
			let paginatedValues = filteredValues.slice(firstIndex, lastIndex);
	
			return (				
				<div key={searchResultsTitle} className="flex flex-col mb-4 p-6 rounded-lg border-gray-200 border-1 h-full">
					<ul className="list-none mb-3.5">
						<div className="mb-3.5 flex items-center justify-between">
							<div className="flex flex-col w-full">
								<div className="flex justify-between">
									<strong className="flex items-center">
										{typeOfResult === "Resolved Cases" ? `We have ${rcCaseNums} case${rcCaseNums === 1 ? '' : 's'} for the following:` :
										typeOfResult === "Serial Number Lookup" ? `Product Results: ${searchResultsTitle}` :
										typeOfResult === "Website Lookup" ? "Website Results:" :
										typeOfResult === "Manuals" ? `${searchResultsTitle} ${typeOfResult}` : ""}
									</strong>
									
									{(typeOfResult === "Resolved Cases") && 
										<>
											<div className="flex h-auto items-center">
												<Button className='whitespace-nowrap' type="button" onClick={() => setIsOpen(true)}>
													Show Case Numbers
												</Button>
											</div>
											<Alert open={isOpen} onClose={setIsOpen}>
												<AlertTitle>Case Numbers</AlertTitle>
												<div className="text-sm w-full flex justify-center">
													<div className="w-full overflow-y-scroll h-96 text-black">
														<ul className="text-center">
															{(searchResultsPartsRC.casesList).map((value, index) => (
																<li key={index}>{value}</li>
															))}
														</ul>
													</div>
												</div>
												<AlertActions>
													<Button onClick={() => setIsOpen(false)}>
														Back to Results
													</Button>
												</AlertActions>
											</Alert>
										</>
									}
								</div>
	
								<p>{(typeOfResult === "Manuals") ? `${filteredValues.length} Results` : ""}</p>
	
							</div>
						</div>
						<div className='h-fit'>
							<Table>
								<TableBody>
									{paginatedValues.map((value, index) => (
										correctResultRow(
											typeOfResult,
											(
												(value.mfgstockcode && value.mfgname && value.description) ? `${searchResultsTitle}-${value.mfgstockcode}-${value.mfgname}-${value.description}-${index}` :
												(value.mfgstockcode) ? `${searchResultsTitle}-${value.mfgname}-${value.mfgstockcode}-${index}` : 
												`${searchResultsTitle}-${index}`
											),
											value,
											searchResultsTitle
										)
									))}
								</TableBody>
							</Table>
						</div>
					</ul>
					<PaginationSection
						totalItems={filteredValues.length}
						itemsPerPage={itemsPerPage}
						currentPage={correctPageNumDict[searchResultsTitle]}
						onPageChange={(newPageNumber) => handlePageChange(typeOfResult, searchResultsTitle, newPageNumber)}
					/>
				</div>
			);
		});
	}


	//Which results to map and render
	function renderSearchResults(searchResultsManuals, searchResultsPartsRC, searchResultsPartsSNL, searchResultsPartsWebsite) {
		if (Object.keys(searchResultsPartsRC).length > 0 || Object.keys(searchResultsPartsSNL).length > 0 || Object.keys(searchResultsPartsWebsite).length > 0 || Object.keys(searchResultsManuals).length > 0) {
			return (
				<div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
					<ul>
						<div className="max-h-full">
							<div className="font-bold text-xl mb-2 text-black dark:text-white">A. Past Resolved Cases</div>
							{searchResultListMap((searchResultsPartsRC.parts ? searchResultsPartsRC.parts : {}), "Resolved Cases", searchResultsPartsRC.numberOfCases)}
						</div>
					</ul>
					<ul>
						<div className="max-h-full">
							<div className="font-bold text-xl mb-2 text-black dark:text-white">B. Serial Number Lookup</div>
							{searchResultListMap(searchResultsPartsSNL, "Serial Number Lookup")}
						</div>
					</ul>
					<ul>
						<div className="max-h-full">
							<div className="font-bold text-xl mb-2 text-black dark:text-white">C. Website Lookup</div>
							{searchResultListMap(searchResultsPartsWebsite, "Website Lookup")}
						</div>
					</ul>
					<ul className="md:col-span-3">
						<div className="h-fit">
							{searchResultListMap(searchResultsManuals, "Manuals")}
						</div>
					</ul>
					<div className="md:col-span-3 flex flex-row w-full justify-end">
						<Button
							className='h-full min-w-fit'
							aria-label="Support"
							color='red'
							href={`/results/mismatch/man=${encodeURIComponent(urlMfgName)}/sn=${encodeURIComponent(urlSN)}/mn=${encodeURIComponent(urlMN)}/refine=${encodeURIComponent(urlRQ)}`}
						>
							<CircleHelp />
							Can't Find Part
						</Button>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<p className="mt-3">No results found.</p>
				</div>
			);
		}
	}


	//Actual return statement for SearchResultsColumns
	return (
		<div className="w-full flex items-center justify-center">
			<div className="mt-0 flex flex-col w-11/12">
				<h1 className="font-bold text-3xl w-full">Part Results</h1>
				<Divider className="mt-2 mb-3"/>
				<h2 className="font-bold text-xl w-full">{urlMfgName ? `Manufacturer: ${urlMfgName}` : ""}{urlMfgName && urlSN ? " / " : ""}{urlSN ? `Serial Number: ${urlSN}` : ""}{(urlMfgName || urlSN) && urlMN ? " / " : ""}{urlMN ? `Model: ${urlMN}` : ""}</h2>
				<h2 className="font-bold text-xl w-full">{urlRQ ? `Description: ${urlRQ}` : ""}</h2>
				{renderSearchResults(searchResultsManuals, searchResultsPartsRC, searchResultsPartsSNL, searchResultsPartsWebsite)}
			</div>
		</div>
	);
}

export default SearchResultsColumns;
